





























































































































































































































































































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import api from '@/api'

@Component({
    name: "couponDetail",
    components: {},
})
export default class ComponentName extends Vue {
    dialogVisible = false
    dataVal: any = [];
    outorg_name: any = []
    channel: any = []
    adv_channel_codes: any = []
    outorg_codes: any = []
    private useConditions = "1";
    private isEdit = true;
    private config: any = {
        outorg: [],
        trade: []
    };
    private form: any = {}
    private formC: any = {}
    checkAll = false
    checkAll2 = false
    isIndeterminate = true
    isIndeterminate2 = true

    // 修改
    modify() {
        console.log('this.formC', this.formC);
        this.formC.channels = this.channelListC

        api
            .post('J27304', this.formC)
            .then(res => {
                this.$message.success('操作成功')
                this.dialogVisible = false
                this.getData()
            })
    }


    handleClose() {
        this.dialogVisible = false
    }

    protected async getData() {
        api
            .post('J27303', {couponNumber: this.$route.query.couponNumber})
            .then(res => {
                this.form = res
                this.formC = res
                if(res.channels.length) {
                    this.showIconChannel = false
                    this.channelListC = res.channels
                }
                if(res.useCondition != '0') {
                    this.useConditions = '2'
                }
                this.dataVal = res.validityPeriod.split('-')
            })
    }


    // 发放优惠券
    grantDialog = false
    formGrant: any = {}
    showIcon = true
    zsjjrObj: any = {}
    close() {
        this.grantDialog = false
    }

    grant() {
        this.grantDialog = true
    }
  fafangLoading = false
    identifyData(){
        let obj = {
            couponNumber: this.form.couponNumber,
            sendType: this.formGrant.sendType,
            phones: this.formGrant.phones,
            marks: this.formGrant.marks,
            brokerId: this.zsjjrObj.id
        }
        this.fafangLoading = true
        api.post('J27421', obj)
            .then(res => {
                this.$message.success('操作成功')
                this.grantDialog =false
                this.getData()
            }).finally(() => {
              this.fafangLoading = false
        })
    }
    //专属经纪人
    addAgentDialog = false
    keyword = ''
    tableArr = []
    SUPERS() {
      api.post('J27319', {keyword: this.keyword})
          .then(res => {
            this.tableArr = res.list
          })
    }
    zsjbr = []
    handleSelectionChange(val: any) {
      console.log(val);
      this.zsjjrObj = val[0]
    }
    closeAddDialog() {
      this.addAgentDialog = false
    }
    del() {
        this.showIcon = true
        this.zsjjrObj = {}
    }
    add() {
        this.addAgentDialog = true
        this.tableArr = []
        this.keyword = ''
    }

  sureAgent() {
      this.showIcon = false
      this.addAgentDialog = false
  }

    activated() {
        this.getData()
        this.getConfig()
        this.getConfig2()
    }

    //判断整数
    isInteger(obj: any) {
        return obj % 1 === 0
    }

    // 可用渠道
    showIconChannel = true
    channelList: any = []
    channelListC: any = []
    channelDialog = false
    channelData: any = []
    // 搜索条件
    search2: any = {
        name: '',
        bond_type: '',
        project_type: '',
        // pagination: {
        //     page: 0, //页码，从0开始
        //     pageSize: 100, //每页数量
        // },
    };
    total = 0

    getConfig2() {
        api
            .post('A23007')
            .then(res => {
                this.config.bond_type = res.bond_type
                this.config.project_type = res.project_type
            })
    }

    getChannel() {
        this.channelData = []
        api
            .post('A23006', this.search2)
            .then(res => {

                this.channelDialog = true

                res.list.forEach((item: any) => {
                    this.channelData.push({
                        bindCode: item.channel_id,
                        bindName: item.name,
                        bond_type: item.bond_type,
                        project_type: item.project_type
                    })
                })

                // this.channelData = res.list
                this.total = res.total
            })
    }

    changePage2(page: number) {
        this.search2.pagination.page = page - 1;
        this.getChannel()
    }

    closeChannelDialog() {
        this.channelDialog = false
    }
    handleChannelChange(rows: any){
        let selectedData: any = []
        if (rows) {
            rows.forEach((row: any) => {
                if (row) {
                    selectedData.push(row);
                }
            });
        }
        this.channelList = selectedData
    }
    sureChannel() {
        if(this.channelList.length != 0) {
            this.showIconChannel = false
            // this.channelListC = []
            this.channelListC = [...this.channelList]
            // this.form.organizations.forEach((item: any) => {
            //   this.form.organizations.push({
            //     bindCode: item.outorg_code,
            //     bindName: item.outorg_name
            //   })
            // })
        }
        this.channelDialog = false
    }

    delChannelTag(row: any) {
        this.channelList.splice(this.channelList.findIndex((item: any) => item.channel_id === row.channel_id), 1)
        this.channelListC = [...this.channelList]
        if(this.channelList.length == 0) {
            this.showIconChannel = true
        }
    }
    delChannelAll() {
        this.channelListC = []
        this.showIconChannel = true
    }
    editChannelList() {
        this.channelList = [...this.channelListC]
        this.channelDialog = true
    }

    openC() {
        this.channelData = [];
        api.post('A23006', this.search2)
            .then(res => {
                this.channelData = []
                res.list.forEach((item: any) => {
                    this.channelData.push({
                        bindCode: item.channel_id,
                        bindName: item.name,
                        bond_type: item.bond_type,
                        project_type: item.project_type
                    })
                })
                let selected = this.channelList;//默认要选择复选框的数据
                (this.$refs as any).table2.clearSelection();
                selected.forEach((item:any) => { // checkedData为已选数据
                    this.$nextTick( ()=>{
                        this.channelData.find((obj: any) => { // userData 表单数据
                            if(item.bindCode === obj.bindCode) {
                                (this.$refs.table2 as any).toggleRowSelection(obj,true)
                            }
                        })
                    })
                })
            })
    }
    getRowKey(row: any) {
        return row.channel_id
    }



    // 编辑
    protected sure() {
        if (!this.form.name) {
            this.$message.warning('请输入优惠券名称')
            return
        }
        if (!this.form.value) {
            this.$message.warning('请输入优惠券面值')
            return
        }

        if (this.dataVal.length == 0) {
            this.$message.warning('请输入有效期')
            return
        }
        if (!this.isInteger(this.form.effective)) {
            this.$message.warning('有效期必须为整数')
            return
        }
        if (this.form.each_cf === '') {
            this.$message.warning('请输入每人限领数量')
            return
        }
        if (this.channel.length == 0) {
            this.$message.warning('请选择可用渠道')
            return
        }


        this.form.validityPeriodStart = this.dataVal[0]
        this.form.validityPeriodEnd = this.dataVal[1]
        // this.form.ava_channel_codes = this.ava_channel_codes.toString()
        // this.form.adv_channel_codes = this.adv_channel_codes.toString()
        this.form.outorg_code = this.outorg_codes.toString()
        console.log(this.form);
        //无门槛
        if (this.useConditions == '1') {
            this.form.condition = '0'
        }

    }

    // 上下线
    protected isUp() {
        const loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(255, 255, 255, 0.7)",
        });
        api
            .post('J27305', {couponNumber: this.form.couponNumber})
            .then(res => {
                this.$message.success('操作成功')
                this.getData()
            }).finally(() => {
                loading.close()
        })

    }

    protected isDown() {
        const h = this.$createElement;
        this.$msgbox({
            title: "操作提示",
            message: h("div", {}, [
                h("p", {}, "下线后经纪人将不能分享该优惠券，是否下线？"),
                h("p", {style: "font-size: 12px"}, "已领取优惠券不受影响"),
            ]),
            showCancelButton: true,
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            beforeClose: (action, instance, done) => {
                if (action == 'confirm') {
                    api
                        .post('J27306', {couponNumber: this.form.couponNumber})
                        .then(res => {
                            this.$message.success('操作成功')
                            this.getData()
                            done();
                        })

                } else {
                    done();
                }
            },
        });
    }

    /**删除 */
    protected async toDelete(item: any) {
        if (item.status == 1) {
            this.$alert('请先下线再召回', '提示', {
                confirmButtonText: '确定',
                callback: action => {

                }
            })
            return
        }
        const h = this.$createElement;
        this.$msgbox({
            title: "操作提示",
            message: h("div", {}, [
                h(
                    "p",
                    {},
                    "召回优惠券，将同步从经纪人账号中删除该优惠券，是否召回？"
                ),
                h("p", {style: "font-size: 12px"}, "已领取优惠券经办人也将同步删除"),
            ]),
            showCancelButton: true,
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            beforeClose: (action, instance, done) => {
                if (action == 'confirm') {
                    api
                        .post('J27309', {couponNumber: this.form.couponNumber})
                        .then(res => {
                            this.$message.success('操作成功')
                            this.getData()
                            done()
                        })

                } else {
                    done()
                }
            },
        });
    }

    /**字典 */
    getConfig() {
        api
            .post('A23005')
            .then(res => {
                this.config.outorg = res.list
            })

        api
            .post('A23006')
            .then(res => {
                this.config.trade = res.list
            })

    }
}
